/**
 * Type: ページ
 * What: 団体利用LP
 */
import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import Image from '../../util/Image';
import { Button } from '../../components/Btn';

const pageTitle = 'イベントホール（貸しホール）特色・使用実績';
const pageDescription =
  '6Fイベントホールは、多目的にご利用いただける貸ホールです。最先端のアートからサブカルチャーまで様々な展示会、イベント等でご利用いただけます。';
const pageSlug = 'eventhall';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <h3 className="headline pc-mb60 sp-mb30">
          <span>特色・使用実績</span>
        </h3>
        <div className="inner">
          <p className="text is-3 is-3-sp pc-mb20 sp-mb10">
            自由自在なレイアウトで多様なイベントを実現
            <br />
            イベントホールは実施するイベントに合わせて、様々なレイアウトでのご使用が可能です。
            <br />
            展示会から移動動物園まで、幅広いイベントを実施しております。
          </p>
          <div className="sc-columns">
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-1.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    展示会
                    <br />
                    (もっともっとギータ先生展)
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-2.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    移動動物園
                    <br />
                    (完全自己責任！？ふれあい動物園ノースサファリサッポロ)
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrap mt-5">
            <a
              href="/special/pastevent/"
              className="btn-regular wide"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>その他のイベントはこちら</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h4 className="headline pc-mb60 sp-mb30">
          <span>
            充実した搬出入設備で
            <br className="sp" />
            円滑なイベント設営を実現
          </span>
        </h4>
        <div className="inner">
          <p className="text is-3 is-3-sp pc-mb20 sp-mb10">
            荷物の搬出入には、積載重量5000kgのリフターを利用可能。
            <br className="sp" />
            リフターはイベントホールに直結しており、スムーズな搬出入を実現します。
            <br className="sp" />
            リフター前には10tトラックの横づけが可能で大規模なイベントへの対応もできます。
          </p>
          <div className="sc-columns">
            <div className="col col-4 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-3.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">6F搬入出スペース</h5>
                </div>
              </div>
            </div>
            <div className="col col-4 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-4.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">車両・荷物用リフター前</h5>
                </div>
              </div>
            </div>
            <div className="col col-4 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-5.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">車両・荷物用リフター</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h4 className="headline pc-mb60 sp-mb30">
          <span>連動性のあるイベントを実現</span>
        </h4>
        <div className="inner">
          <p className="text is-3 is-3-sp pc-mb20 sp-mb10">
            MLB Café でドリンクからフードまで多様コラボメニューで提供可能です。
            <br className="sp" />
            また、同施設内よしもと福岡 大和証券劇場
            にてステージイベントの開催など、お客様へと様々な角度からのアプローチもご検討いただけます。※
            <sup>1</sup>
          </p>
          <div className="sc-columns mb30">
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-6.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    コラボグルメ
                    <br />
                    (完全自己責任！？ふれあい動物園ノースサファリサッポロ)
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="main-yoshimoto.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    よしもと福岡 大和証券劇場
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <p className="text is-3 is-3-sp mb30">
            みずほPayPayドーム横の好立地を生かし、野球開催時にセレモニアルピッチ(始球式)でのイベントに関連したキャラクターの参加や、野球中継内の告知や来場者に対する大型ビジョンでの告知など、E・ZOならではの展開も。※
            <sup>2</sup>
          </p>
          <div className="sc-columns sp-mb10">
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-8.jpg" />
                  </figure>
                </div>
                <div className="col-content col-credit-wrap">
                  <p className="credit c-4">
                    © 2022 SANRIO CO., LTD. APPROVAL NO. SP630661
                  </p>
                  <h5 className="schedule-title">
                    セレモニアルピッチへのキャラクターの登場
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhal-feature-9.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">野球中継内での告知</h5>
                </div>
              </div>
            </div>
          </div>
          <p className="list-mark">
            ※<sup>1</sup> 施設の状況などにより開催できない場合がございます。
          </p>
          <p className="list-mark">
            ※<sup>2</sup> 空き状況により実施できない場合がございます。
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
